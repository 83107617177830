
import { defineComponent } from 'vue'
import PageContentSidebarList from '@/components/layout/pageContent/sidebar/PageContentSidebarList.vue'
import { ticketsSettingsSidebarRoutes } from '@/router/sidebarRoutes/ticketsSettingsSidebarRoutes'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    PageContentSidebarList,
  },
  setup() {
    const { openModal } = useModals()

    const onItemClick = (name: string) => {
      if (name === 'subdomain') {
        openModal('ticketsSubdomain')
      }
    }

    return {
      onItemClick,
      ticketsSettingsSidebarRoutes,
    }
  },
})
