import type { PageContentSidebarItem } from '@/definitions/_layout/pageContent/sidebar/types'

export const ticketsSettingsSidebarRoutes: PageContentSidebarItem[] = [
  {
    itemType: 'link',
    title: 'All settings',
    route: { name: 'base.tickets.settings.index' },
  },
  {
    itemType: 'title',
    title: 'Channels',
  },
  {
    itemType: 'link',
    title: 'Connected inboxes',
    route: { name: 'base.tickets.settings.inboxes' },
  },
  {
    itemType: 'link',
    name: 'subdomain',
    title: 'Workspace subdomain',
  },
  {
    itemType: 'link',
    title: 'Spam filter',
    route: { name: 'base.tickets.settings.spamFilter' },
  },
  {
    itemType: 'title',
    title: 'Other settings',
  },
  {
    itemType: 'link',
    title: 'General',
    route: { name: 'base.tickets.settings.general' },
  },
  {
    itemType: 'link',
    title: 'Filtered views',
    route: { name: 'base.tickets.settings.filteredViews' },
  },
  {
    itemType: 'link',
    title: 'Data fields',
    route: { name: 'base.tickets.settings.dataFields' },
  },
  {
    itemType: 'link',
    title: 'Statuses',
    route: { name: 'base.tickets.settings.statuses' },
  },
  {
    itemType: 'link',
    title: 'Canned responses',
    route: { name: 'base.tickets.settings.cannedResponses' },
  },
  {
    itemType: 'title',
    title: 'Email templates',
  },
  {
    itemType: 'link',
    title: 'Auto-responder',
    route: { name: 'base.tickets.settings.autoResponder' },
  },
  {
    itemType: 'link',
    title: 'Feedback survey',
    route: { name: 'base.tickets.settings.feedbackSurvey' },
  },
  {
    itemType: 'link',
    title: 'Public reply',
    route: { name: 'base.tickets.settings.publicReply' },
  },
]
